<template>
  <div></div>
</template>

<script>
export default {
  name: 'ComputopError',
  mounted() {
    if (typeof window !== 'undefined') {
      window.parent.postMessage({
        type: 'COMPUTOP__ERROR',
      });

      setTimeout(() => {
        window.parent.postMessage({
          type: 'COMPUTOP__FAILURE',
        });
      }, 250);
    }
  },
};
</script>

<style scoped lang="scss">
</style>
